import React from 'react';

import ListItem from './list-item';

import { useData } from '../data/data';

import './list.scss';

const List = () => {

    const { posts } = useData();

    const renderList = () => {
        return posts.map( ( post, index ) => {
            return <ListItem key={index} data={{ ...post, type: 'compact' }}/>;
        } );
    };

    return <ul className="list">{renderList()}</ul>;

};

export default List;
