import React, { useContext, useEffect } from 'react';

import Orbits from '../components/orbits';
import List from '../components/list';
import SEO from '../components/seo';

import { LayoutContext } from '../components/layout';

const IndexPage = () => {

    const layoutContext = useContext( LayoutContext );

    useEffect( () => {
        layoutContext.setResetScroll( true );
    }, [] );

    return <main>
        <SEO title="Index" />
        <Orbits />
        <List />
    </main>;
};

export default IndexPage;
