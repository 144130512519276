import React from 'react';

import Orbit from './orbit';
import { useData } from '../data/data';

import './orbits.scss';

const Orbits = () => {

    const { posts, pages } = useData();

    const items = [ ...posts, ...pages ];

    const renderOrbits = () => {
        return items.map( ( item, index ) => {
            return <Orbit key={index} index={index} item={item} />;
        } );
    };

    return <section className="orbits">
        <p className="intro">Art ~ Architecture ~ Biology ~ Chemistry ~ Dendrology ~ Earth Science ~ Geology ~ ... & Zoology</p>
        {renderOrbits()}
    </section>;

};

export default Orbits;
