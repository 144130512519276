import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useWindowSize } from './window-size';
import { useData } from '../data/data';

import './orbit.scss';

const Orbit = ( { index, item } ) => {

    const image = getImage( item.thumbnail );

    const { posts, pages } = useData();

    const orbitRef = useRef();
    const mountedRef = useRef();
    const frameRef = useRef();

    const windowSize = useWindowSize();

    let orbitSpeedGlobal, orbitSpeedLocal, skewOffset, angleGlobal, angleLocal;

    if ( item.type === 'post' ) {

        orbitSpeedGlobal = useRef( ( Math.random() * .3 + .2 ) * -.0075 );
        orbitSpeedLocal = useRef( ( Math.random() * 1 - .5 ) * .03 );
        skewOffset = useRef( Math.random() - .5 );

        angleGlobal = useRef( ( Math.PI * 2 ) * ( index / posts.length ) );
        angleLocal = useRef( 0 );

    } else if ( item.type === 'page' ) {

        orbitSpeedGlobal = useRef( ( Math.random() * .3 + .2 ) * .02 );
        orbitSpeedLocal = useRef( ( Math.random() * 1 - .5 ) * .03 );
        skewOffset = useRef( Math.random() - .5 );

        angleGlobal = useRef( ( Math.PI * 2 ) * ( index / pages.length ) );
        angleLocal = useRef( 0 );

    }

    let w;
    let h;

    let px;
    let py;

    const [ hover, setHover ] = useState( false );

    useEffect( () => {

        mountedRef.current = true;
        frameRef.current = requestAnimationFrame( animate );

        return () => {
            cancelAnimationFrame( frameRef.current );
            mountedRef.current = false;
        };

    }, [ hover, windowSize ] );

    const animate = () => {

        if ( mountedRef.current === true ) {

            if ( !hover ) {

                angleGlobal.current = angleGlobal.current + orbitSpeedGlobal.current % ( Math.PI * 2 );
                angleLocal.current = angleLocal.current + orbitSpeedLocal.current % ( Math.PI * 2 );

                if ( item.type === 'post' ) {
                    w = windowSize.width / 3;
                    h = windowSize.height / 3;
                } else if ( item.type === 'page' ) {
                    if ( windowSize.width < windowSize.height ) {
                        w = windowSize.width / 7;
                        h = windowSize.width / 7;
                    } else {
                        w = windowSize.height / 7;
                        h = windowSize.height / 7;
                    }
                }

                px = ( w - ( Math.cos( angleLocal.current ) * ( w / 4 ) * Math.cos( angleGlobal.current ) ) ) * Math.cos( angleGlobal.current );
                py = ( h + ( Math.sin( angleLocal.current ) * ( h / 4 ) * Math.sin( angleGlobal.current ) ) ) * Math.sin( angleGlobal.current );

            }

            orbitRef.current.style.transform = `translate3d( calc(${px + window.innerWidth / 2}px - 50%), calc(${py + ( window.innerHeight / 2 ) - ( px * skewOffset.current * .2 )}px - 50%), 0)`;

            frameRef.current = requestAnimationFrame( animate );
        }

    };

    const renderThumbnail = () => {
        if ( image ) {
            return <GatsbyImage image={image} alt={item.title} objectFit="contain" />;
        } else {
            return <h2 className="title">{item.title}</h2>;
        }
    };

    return <section className='orbit' ref={orbitRef} onMouseOver={() => { setHover( true ); }} onMouseLeave={() => { setHover( false ); }}>
        <div className="image" >
            <Link to={item.slug}>
                {renderThumbnail()}
            </Link>
        </div>
    </section>;

};

export default Orbit;
